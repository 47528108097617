<template>
  <Layout>
    <div>
      Cx System
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import Store from '@store';
import { isMexico } from '@utils/helper-region';

export default {
  name: 'Cx',
  page: {
    title: 'Cx',
  },
  components: {
    Layout,
  },
  mounted () {
    const authToken = Store.getters['user/authToken'];

    const url = isMexico() ? `${process.env.CX_SYSTEM_URL_MX}?rentalAccessToken=${authToken}` : process.env.CX_SYSTEM_URL;

    window.open(url, '_blank');
    this.$router.push(
      this.$route.query.redirect || {
        path: '/dashboard',
      }
    );
  },
};
</script>
